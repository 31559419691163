<template>
  <v-app>
    <default-view />
  </v-app>
</template>

<script>
  export default {
    name: 'RestrictedLayout',
    components: {
      DefaultView: () => import(
        /* webpackChunkName: "default-view" */
        '../default/View'
      ),
    },
  }
</script>
